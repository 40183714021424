import React, { useCallback } from 'react';

import { useTenantSetting, useTenantSettings } from '@nestoca/multi-tenant';
import { Box } from '@nestoca/ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRouter } from 'next/router';
import {
    BsBox,
    BsAppIndicator,
    BsDownload,
    BsExclamationSquare,
    BsFileEarmarkText,
    BsHeadset,
    BsPercent,
    BsPen,
    BsSearch,
    BsLayoutTextWindow,
    BsTools,
    BsRobot,
    BsClockHistory,
} from 'react-icons/bs';
import { FiSend } from 'react-icons/fi';
import { constSelector, useRecoilValue } from 'recoil';

import { ScrollArea } from 'components/scroll-area';
import { useClient } from 'providers/auth0';
import { useI18n } from 'providers/i18n/use-i18n';
import { useModal } from 'providers/modals/use-modal';
import { applicationListState, selectedApplication } from 'store/applications';
import {
    defaultDecisionEngineReportDetailsState,
    getDecisionEngineReportDetails,
} from 'store/decision-engine';
import { useEditingRights, useClosingDocsRights } from 'store/rights';
import { CreatedBy } from 'types/application';

import { PersonRolodex } from '../icons/person-rolodex';

import { ContentLink } from './content-link';
import styles from './primary-sidebar.module.scss';

export type LinkOptions = {
    id: string;
    visible?: boolean;
    href: string;
    active: boolean;
    dataTestId: string;
    disabled?: boolean;
    icon: React.ReactElement;
    label: string;
    tooltipDisabled?: boolean;
    tooltipContent: React.ReactNode;
    warning?: boolean;
    onClick?: () => void;
};

type Props = {
    isSidebarOpen: boolean;
};

export const Content = ({ isSidebarOpen }: Props) => {
    // load deps first
    // do BE request to get the list of applications if not done yet
    useRecoilValue(applicationListState);
    const router = useRouter();
    const selectedApp = useRecoilValue(selectedApplication);
    const hasEditingRights = useEditingRights();
    const { i18n } = useI18n();
    const { client } = useClient();
    const { open: openModal } = useModal('logIssue');
    const { view: hasClosingDocsViewRights } = useClosingDocsRights();

    const {
        enableDeededSubmission: { value: enableDeededSubmission },
        enableMortgageTools: { value: enableMortgageTools },
        closingDocuments: { value: newClosingDocuments },
    } = useTenantSettings();

    const {
        documentCenter,
        applicationReview,
        isSdr,
        newSdrFlow,
        paradigmSync,
        middleOfficeSync,
        submissionNotes,
        rateCompareTool,
        useNewUwFlow,
        deededNotaryService,
        hasConditionsManagementServiceRights,
    } = useFlags();

    const { value: enableOptimus } = useTenantSetting('enableOptimus');

    const decisionEngineReportDetails = useRecoilValue(
        selectedApp
            ? getDecisionEngineReportDetails(selectedApp.id)
            : constSelector(defaultDecisionEngineReportDetailsState)
    );

    const onLogIssueClick = useCallback(() => {
        const params = new URLSearchParams({
            ...(client?.rid && { accountRid: `${client.rid}` }),
            ...(client?.rid && selectedApp?.id && { applicationId: `${selectedApp.id}` }),
        });

        navigator.clipboard.writeText(
            `${window.location.origin}/login?${params.toString()}`
        );
        openModal({ rid: client?.rid });
    }, [client?.rid, selectedApp?.id, openModal]);

    const helocData = selectedApp?.heloc;
    const isHelocAmountPresent = helocData?.helocAmount;

    const isStandAloneHeloc =
        !!isHelocAmountPresent && !selectedApp?.mortgage?.product;

    let isSubmissionNotesEnabled = false;
    if (isStandAloneHeloc) {
        // Standalone HELOC is always enabled
        isSubmissionNotesEnabled = true;
    } else if (
        selectedApp &&
        selectedApp.createdBy === CreatedBy.EXTERNAL_BROKER
    ) {
        // External broker created applications are always enabled
        isSubmissionNotesEnabled = true;
    } else if (selectedApp && selectedApp.type === 'PRE_APPROVAL') {
        // Pre-approval applications are enabled
        // if purchase price is present
        isSubmissionNotesEnabled = !!selectedApp.property?.purchasePrice;
    } else if (selectedApp) {
        // All other applications are enabled if product is present
        isSubmissionNotesEnabled =
            !!selectedApp.product && selectedApp.property?.isFound;
    }

    const closingDocumentsSlug = newClosingDocuments
        ? `closing-documents`
        : `agreements`;

    const linksOptions: LinkOptions[] = [
        {
            id: 'nav-qualification-flow',
            visible: newSdrFlow,
            href: `/applications/${selectedApp?.id}/qualification`,
            active:
                router.pathname ===
                '/applications/[applicationId]/qualification',
            dataTestId: 'nav-qualification-flow',
            disabled: !selectedApp,
            icon: <BsHeadset key="icon-head-set" title={null} />,
            label: i18n._('qualification.title'),
            tooltipContent: i18n._('qualification.title'),
        },
        {
            id: 'nav-dashboard',
            href: '/',
            active: router.pathname === '/',
            dataTestId: 'nav-dashboard',
            icon: <BsAppIndicator key="icon-app-indicator" title={null} />,
            label: i18n._('Dashboard'),
            tooltipContent: i18n._('Dashboard'),
        },
        {
            id: 'nav-underwriter-flow',
            visible: useNewUwFlow,
            href: '/uw-flow',
            active: router.pathname.startsWith('/uw-flow'),
            dataTestId: 'nav-underwriter-flow',
            disabled: !selectedApp?.product && !selectedApp?.heloc,
            icon: (
                <BsLayoutTextWindow
                    key="icon-layout-text-window"
                    title={null}
                />
            ),
            label: i18n._('underwriterReview'),
            tooltipContent: i18n._('underwriterReview'),
        },
        {
            id: 'nav-sdr',
            visible: isSdr,
            href: '/sdr',
            active: router.pathname === '/sdr',
            dataTestId: 'nav-sdr',
            disabled: !selectedApp,
            icon: <BsHeadset key="icon-head-set" title={null} />,
            label: i18n._('sdrQualification'),
            tooltipContent: i18n._('sdrQualification'),
        },
        {
            id: 'nav-applicationReview',
            visible: !isSdr && applicationReview,
            href: `/applications/${selectedApp?.id}`,
            active: router.pathname === '/applications/[applicationId]',
            dataTestId: 'nav-applicationReview',
            disabled: !selectedApp,
            icon: <BsSearch key="icon-search" title={null} />,
            label: i18n._('applicationReview'),
            tooltipContent: i18n._('applicationReview'),
        },
        {
            id: 'nav-documents',
            visible: !isSdr && documentCenter,
            href: `/applications/${selectedApp?.id}/documents`,
            active: router.pathname.startsWith(
                '/applications/[applicationId]/documents'
            ),
            dataTestId: 'nav-documents',
            disabled: !selectedApp,
            icon: <BsFileEarmarkText key="icon-file-earmark" title={null} />,
            label: i18n._('documentsCenter.sideNav'),
            tooltipContent: i18n._('documentsCenter.sideNav'),
        },
        {
            id: 'nav-submission-notes',
            visible: !isSdr && submissionNotes,
            href: `/applications/${selectedApp?.id}/submission-notes`,
            active: [
                '/applications/[applicationId]/submission-notes/[submissionNotesId]',
                '/applications/[applicationId]/submission-notes',
            ].includes(router.pathname),
            dataTestId: 'nav-submission-notes',
            disabled: !isSubmissionNotesEnabled,
            icon: <BsPen key="icon-pen" title={null} />,
            label: i18n._('submissionNotes'),
            tooltipContent: i18n._('submissionNotes'),
        },
        {
            id: 'nav-rates',
            visible: !isSdr,
            href: '/rates',
            active: router.pathname.startsWith('/rates'),
            dataTestId: 'nav-rates',
            disabled: !selectedApp,
            icon: <BsPercent key="icon-percent" title={null} />,
            label: i18n._('Rates'),
            tooltipContent: i18n._('Rates'),
        },
        {
            id: 'nav-filogix-sync',
            visible: !isSdr && middleOfficeSync,
            href: '/sync/start',
            active: router.pathname.startsWith('/sync'),
            dataTestId: 'nav-filogix-sync',
            disabled: !selectedApp || !hasEditingRights,
            icon: <BsDownload key="icon-download" title={null} />,
            label: i18n._('Filogix Synchronization'),
            tooltipContent: i18n._('Filogix Synchronization'),
        },
        {
            id: 'nav-export',
            visible: !isSdr && paradigmSync,
            href: '/insurer-submission',
            active:
                router.pathname.startsWith('/export-servicing-data') ||
                router.pathname.startsWith('/insurer-submission'),
            dataTestId: 'nav-export',
            disabled: !selectedApp || !hasEditingRights,
            icon: <FiSend key="icon-send" title={null} />,
            label: i18n._('exportServicingData.nav'),
            tooltipContent: i18n._('exportServicingData.nav'),
        },
        {
            id: 'nav-optimus',
            visible: enableOptimus,
            href: `/applications/${selectedApp?.id}/optimus`,
            active: router.pathname.includes(
                '/applications/[applicationId]/optimus'
            ),
            dataTestId: 'nav-optimus',
            disabled: !decisionEngineReportDetails.isReportExist,
            label: i18n._('optimus.title'),
            icon: <BsRobot />,
            tooltipContent: i18n._('optimus.title'),
            warning: decisionEngineReportDetails.hasNotification,
        },
        {
            id: 'nav-deeded-submission',
            visible:
                !isSdr &&
                paradigmSync &&
                deededNotaryService &&
                enableDeededSubmission,
            href: '/deeded-submission',
            active: router.pathname.startsWith('/deeded-submission'),
            dataTestId: 'nav-deeded-submission',
            disabled: !selectedApp || !hasEditingRights,
            icon: <PersonRolodex key="icon-notary" title={null} />,
            label: i18n._('deeded.title'),
            tooltipContent: i18n._('deeded.title'),
        },
        {
            id: 'nav-agreements',
            visible: !isSdr,
            href: `/applications/${selectedApp?.id}/${closingDocumentsSlug}`,
            active: router.pathname.startsWith(
                `/applications/[applicationId]/${closingDocumentsSlug}`
            ),
            dataTestId: 'nav-agreements',
            disabled: !selectedApp || !hasClosingDocsViewRights,
            icon: <BsBox key="icon-box" title={null} />,
            label: i18n._('closingDocuments'),
            tooltipContent: i18n._('closingDocuments'),
        },
        {
            id: 'nav-compare-tool',
            visible: !isSdr && rateCompareTool && enableMortgageTools,
            href: '/mortgage-tools',
            active: router.pathname.startsWith('/mortgage-tools'),
            dataTestId: 'nav-compare-tool',
            icon: <BsTools key="icon-tools" title={null} />,
            label: i18n._('mortgageTools'),
            tooltipContent: i18n._('mortgageTools'),
        },
        {
            id: 'nav-conditions-management-service',
            dataTestId: 'nav-conditions-management-service',
            visible: hasConditionsManagementServiceRights,
            href: '/conditions-management-service',
            active: router.pathname.startsWith(
                '/conditions-management-service'
            ),
            icon: <BsFileEarmarkText key="icon-file-text" title={null} />,
            label: i18n._('cms'),
            tooltipContent: i18n._('cms'),
        },
        {
            id: 'nav-app-history',
            visible: selectedApp?.createdBy === CreatedBy.EXTERNAL_BROKER,
            href: `/applications/${selectedApp?.id}/history`,
            active: router.pathname === '/applications/[applicationId]/history',
            dataTestId: 'nav-app-history',
            icon: <BsClockHistory key="icon-clock-history" title={null} />,
            label: i18n._('appHistory'),
            tooltipContent: i18n._('appHistory'),
        },
        {
            id: 'nav-log-issues',
            visible: !isSdr,
            href: '#',
            active: false,
            dataTestId: 'nav-log-issues',
            icon: <BsExclamationSquare key="icon-exclamation" title={null} />,
            label: i18n._('logIssue'),
            tooltipContent: i18n._('logIssue'),
            onClick: () => onLogIssueClick(),
        },
    ];
    return (
        <ScrollArea>
            <Box className={styles.list} as="ul">
                {linksOptions.map((linkOptions) => (
                    <ContentLink
                        key={linkOptions.id}
                        linkOptions={{
                            ...linkOptions,
                            tooltipDisabled: isSidebarOpen,
                        }}
                    />
                ))}
            </Box>
        </ScrollArea>
    );
};
